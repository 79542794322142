import { FC, ReactNode } from "react";

import cn from "classnames";

import classes from "../Grid.module.scss";

import { ICol } from "./types";
import { getColClasses } from "./utils";

interface Props extends ICol {
  className?: string;
  children?: ReactNode;
  /** span, offset, order, align для экрана <= 375px */
  xs?: ICol;
  /** span, offset, order, align для экрана <= 576px */
  sm?: ICol;
  /** span, offset, order, align для экрана <= 768px */
  md?: ICol;
  /** span, offset, order, align для экрана <= 992px */
  lg?: ICol;
  /** span, offset, order, align для экрана <= 1200px */
  xl?: ICol;
  /** span, offset, order, align для экрана <= 1400px */
  xxl?: ICol;
}

/**
 * ### Разработка
 * Компонент колонки, используемый внутри компонента `Grid`.
 *
 * - `span` -- ширина компонента (диапазон, на который растягивается компонент). Если указать число, то компонент будет занимать соответствующее число колонок сетки. Значение `auto` -- компонент будет подстраиваться по ширину контента.
 * - `offset` -- сдвиг компонента слева по количеству колонок сетки.
 * - `order` -- порядок расположения в сетке. `-1` -- перекидывает элемент в самое начало.
 * - `align` -- выравнивание текста.
 * - `valign` -- выравнивание компонента по вертикали в сетке.
 *
 * Используются стандартные брейкпоинты - пропы `sm`, `md`, `lg`, `xl`, `xxl`. В них можно указать свойства `span`, `offset`, `order`, `align`, `valign`, передаются объектом с соответствующими ключами.
 *
 * ```js
 * {
 *      span: 2,
 *      offset: 1,
 *      order: 10,
 *      align: "right",
 *      valign: "center"
 * }
 * ```
 *
 * Для кастомизации можно использовать свойство `className`.
 *
 */
export const Col: FC<Props> = ({
  children,
  className,
  span,
  offset,
  order,
  align,
  valign,
  xs = {},
  sm = {},
  md = {},
  lg = {},
  xl = {},
  xxl = {},
}) => {
  const classNames = cn(
    classes.col,
    getColClasses(classes, { span, offset, order, align, valign }, xs, sm, md, lg, xl, xxl),
    className,
  );
  return <div className={classNames}>{children}</div>;
};
