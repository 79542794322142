import { MainLayout } from "app/layouts/MainLayout";
import { TrafficbackProvider } from "app/providers/TrafficbackProvider";

import { createLazyRoute } from "../utils";

export const trafficbackRoutes = [
  createLazyRoute({
    path: "/trafficback",
    page: "TrafficbackPage",
    layout: <MainLayout title="Trafficback-ссылки | Guruleads" />,
    provider: <TrafficbackProvider />,
  }),
];
