import { createContext, useContext } from "react";

import { TFAStore } from "./store";

export const TFAStoreContext = createContext<TFAStore | null>(null);

export const useTFAStore = () => {
  const context = useContext(TFAStoreContext);
  if (context === null) {
    throw new Error();
  }
  return context;
};
