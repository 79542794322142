import { NotificationPreviewStore } from "./Notification/store";
import { GlobalPostbacksStore } from "./Postback/store";
import { SettingsStore } from "./Settings/store";
import { UserStore } from "./User/store";

export class RootStore {
  globalPostbacksStore = new GlobalPostbacksStore();
  userStore = new UserStore();
  notificationStore = new NotificationPreviewStore();
  settingsStore = new SettingsStore();
}
