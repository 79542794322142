import { useWindowSize as useWindowWidth } from "usehooks-ts";

export const useWindowSize = () => {
  const { width } = useWindowWidth();

  return {
    isPhone: width <= 576,
    isSmallTablet: width <= 768,
    isTablet: width <= 992,
    isLaptop: width <= 1200,
    isDesktop: width <= 1400,
  };
};
