import uniqid from "uniqid";

import { NotificationApi } from "entities/Notification/api";
import { SettingsApi } from "entities/Settings/api";
import { UserApi } from "entities/User/api";

import { IDefaultResponse } from "./types";

import { requestBatch } from "shared/api";

export const defaultBatch = async (): Promise<IDefaultResponse> => {
  const userId = uniqid();
  const balanceId = uniqid();
  const notificationId = uniqid();
  const backgroundId = uniqid();
  const activeTicketsId = uniqid();
  const paymentsCountId = uniqid();
  const { dataUserConfig, dataBalanceConfig, dataUserActiveTicketsConfig, dataUserPaymentsCountConfig } = UserApi();
  const { dataNotificationCountConfig } = NotificationApi();
  const { dataBackgroundConfig } = SettingsApi();

  const response = await requestBatch([
    { ...dataUserConfig, id: userId },
    { ...dataBalanceConfig, id: balanceId },
    { ...dataNotificationCountConfig, id: notificationId },
    { ...dataBackgroundConfig, id: backgroundId },
    { ...dataUserActiveTicketsConfig, id: activeTicketsId },
    { ...dataUserPaymentsCountConfig, id: paymentsCountId },
  ]);
  const result: IDefaultResponse = {
    user: null,
    balance: null,
    notification: null,
    background: null,
    ticketsCount: null,
    paymentsCount: null,
  };
  response.forEach((res) => {
    let key = null;
    if (res.id === userId) {
      key = "user";
    }
    if (res.id === balanceId) {
      key = "balance";
    }
    if (res.id === notificationId) {
      key = "notification";
    }
    if (res.id === backgroundId) {
      key = "background";
    }
    if (res.id === activeTicketsId) {
      key = "ticketsCount";
    }
    if (res.id === paymentsCountId) {
      key = "paymentsCount";
    }
    if (key) {
      result[key as keyof IDefaultResponse] = { ...res };
    }
  });
  return result;
};
