import { FC, useCallback, useState } from "react";

import { LeftSide } from "./components/LeftSide";
import { RightSide } from "./components/RightSide";
import classes from "./Header.module.scss";

import { Container } from "shared/ui/Container";

export const Header: FC = () => {
  const [openBurger, setOpenBurger] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);

  const handleOpenBurger = useCallback(
    (open: boolean) => {
      if (!openBurger) {
        setOpenProfile(false);
        setOpenNotifications(false);
      }
      setOpenBurger(open);
    },
    [openBurger],
  );

  const handleOpenProfile = useCallback(
    (open: boolean) => {
      if (!openProfile) {
        setOpenBurger(false);
        setOpenNotifications(false);
      }
      setOpenProfile(open);
    },
    [openProfile],
  );

  const handleOpenNotifications = useCallback(
    (value?: boolean) => {
      if (typeof value === "boolean") {
        setOpenNotifications(value);
      } else {
        if (!openNotifications) {
          setOpenNotifications(true);
          setOpenProfile(false);
          setOpenBurger(false);
        } else {
          setOpenNotifications(false);
        }
      }
    },
    [openNotifications],
  );

  return (
    <header className={classes.header}>
      <Container fluid className={classes.container}>
        <LeftSide open={openBurger} handleOpen={handleOpenBurger} />
        <RightSide
          openProfile={openProfile}
          openNotifications={openNotifications}
          handleOpenProfile={handleOpenProfile}
          handleOpenNotifications={handleOpenNotifications}
        />
      </Container>
    </header>
  );
};
