import { FC, useEffect } from "react";

import { isRouteErrorResponse, useRouteError } from "react-router-dom";

import { ErrorBoundary } from "widgets/ErrorBoundary";

export const Page: FC = () => {
  const error = useRouteError();

  useEffect(() => {
    // eslint-disable-next-line
    // @ts-ignore
    if (error && typeof error === "object" && error.name && error.name === "ChunkLoadError") {
      document.location.reload();
    }
  }, [error]);
  if (isRouteErrorResponse(error)) {
    return (
      <ErrorBoundary code={error.status} text="Что-то пошло не так, попробуйте позже или перезагрузите страницу" />
    );
  } else {
    return <ErrorBoundary text="Что-то пошло не так, перезагрузите страницу" />;
  }
};
