import { makeAutoObservable } from "mobx";
import uniqid from "uniqid";

import { GlobalPostbackProps } from "./model";

export class GlobalPostbacksStore {
  globalPostbacks: GlobalPostbackProps[] = [
    {
      id: 1,
      url: "https://mysite.ru",
      status: "create",
      secretId: uniqid(),
      type: "on",
    },
  ];

  constructor() {
    makeAutoObservable(this);
  }

  load = () => {
    /** First load */
  };

  get = () => {
    /**get postbacks */
  };

  add = (val: GlobalPostbackProps) => {
    this.globalPostbacks = [...this.globalPostbacks, val];
  };

  update = (val: GlobalPostbackProps) => {
    this.globalPostbacks = this.globalPostbacks.map((postback) => {
      if (postback.secretId === val.secretId) {
        return val;
      }
      return postback;
    });
  };

  delete = (id: string) => {
    this.globalPostbacks = this.globalPostbacks.filter((val) => val.secretId !== id);
  };
}
