import { FC } from "react";

import { ReactComponent as MenuIcon } from "@material-symbols/svg-400/rounded/menu.svg";

import { IHeaderLeftSize } from "../../types";
import { BurgerButton } from "../BurgerButton";
import { Navigation } from "../Navigation";

import classes from "./LeftSide.module.scss";

import { Drawer } from "shared/ui/Drawer";
import { Logo } from "shared/ui/Logo";

export const LeftSide: FC<IHeaderLeftSize> = ({ open, handleOpen }) => {
  return (
    <>
      <div className={classes.left}>
        <BurgerButton className={classes.burger} open={open} setOpen={() => handleOpen(!open)}>
          <MenuIcon className={classes.menu} />
        </BurgerButton>
        <a href={`${process.env.REACT_APP_ACCOUNT_URL}/dashboard`} className={classes.logo}>
          <Logo />
        </a>
        <Navigation />
      </div>
      <Drawer className={classes.drawer} theme="dark" position="left" open={open} setOpen={handleOpen}>
        <Navigation mobile setOpen={() => handleOpen(false)} />
      </Drawer>
    </>
  );
};
