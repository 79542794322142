import { createContext, useContext } from "react";

import { UserDeliveryStore } from "./store";

export const UserDeliveryStoreContext = createContext<UserDeliveryStore | null>(null);

export const useUserDeliveryStore = () => {
  const context = useContext(UserDeliveryStoreContext);
  if (context === null) {
    throw new Error();
  }
  return context;
};
