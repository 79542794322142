import { createContext, useContext } from "react";

import { ProductStore, ProductsStore } from "./store";

export const ProductStoreContext = createContext<ProductStore | null>(null);
export const ProductsStoreContext = createContext<ProductsStore | null>(null);

export const useProductStore = () => {
  const context = useContext(ProductStoreContext);
  if (context === null) {
    throw new Error();
  }
  return context;
};

export const useProductsStore = () => {
  const context = useContext(ProductsStoreContext);
  if (context === null) {
    throw new Error();
  }
  return context;
};
