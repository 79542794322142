import uniqid from "uniqid";

import { IGlobalTrafficbackParams } from "../model";

import { request, setupData } from "shared/api";

export const GlobalTrafficbackApi = () => {
  const dataGlobalTrafficbackConfig = setupData(uniqid("global-trafficback"), "trafficback.get-trafficback-global");

  const getGlobalTrafficback = async () => {
    return await request([{ ...dataGlobalTrafficbackConfig }]);
  };

  const dataGlobalTrafficbackCreateConfig = setupData(
    uniqid("global-trafficback-create"),
    "trafficback.create-trafficback-global",
  );

  const createGlobalTrafficback = async (params: IGlobalTrafficbackParams) => {
    return await request([{ ...dataGlobalTrafficbackCreateConfig, params }]);
  };

  const dataGlobalTrafficbackDeleteConfig = setupData(
    uniqid("global-trafficback-delete"),
    "trafficback.delete-trafficback-global",
  );

  const deleteGlobalTrafficback = async () => {
    return await request([{ ...dataGlobalTrafficbackDeleteConfig }]);
  };

  return {
    dataGlobalTrafficbackConfig,
    dataGlobalTrafficbackCreateConfig,
    dataGlobalTrafficbackDeleteConfig,
    getGlobalTrafficback,
    createGlobalTrafficback,
    deleteGlobalTrafficback,
  };
};
