import { MainLayout } from "app/layouts/MainLayout";
import { ProfileLayout } from "app/layouts/ProfileLayout";
import { NotificationsSettingsProvider } from "app/providers/NotificationsSettingsProvider";

import { createLazyRoute } from "../utils";

export const notificationsRoutes = [
  createLazyRoute({
    path: "/notifications",
    page: "NotificationsPage",
    layout: <ProfileLayout title="Уведомления | Guruleads" />,
  }),
  createLazyRoute({
    path: "/notifications/settings",
    page: "NotificationsSettingsPage",
    provider: <NotificationsSettingsProvider />,
    layout: <MainLayout title="Уведомления | Настройки уведомлений | Guruleads" />,
  }),
];
