import { createContext, FC, useState } from "react";

import { Notification } from "./Notification";
import { NotificationsProviderProps, NotificationsContextProps, NotificationProps } from "./Notification.types";
import { NotificationsWrapper } from "./NotificationsWrapper";

export const NotificationsContext = createContext<NotificationsContextProps | null>(null);

export const NotificationsProvider: FC<NotificationsProviderProps> = ({ children }) => {
  const [notifications, setNotifications] = useState<NotificationProps[]>([]);

  const addNotification = (notification: NotificationProps) => {
    const id = Math.random().toString(36).slice(2, 9) + new Date().getTime().toString(36);
    setNotifications([...notifications, { ...notification, id }]);
  };

  const removeNotification = (id: string) => {
    setNotifications([...notifications.filter((notification) => notification.id !== id)]);
  };

  return (
    <NotificationsContext.Provider value={{ notifications: notifications, addNotification, removeNotification }}>
      <NotificationsWrapper>
        {notifications?.map((notification) => (
          <Notification {...notification} removeNotification={removeNotification} key={notification.id} />
        ))}
      </NotificationsWrapper>
      {children}
    </NotificationsContext.Provider>
  );
};
