import { MainLayout } from "app/layouts/MainLayout";
import { GlobalPostbacksProvider } from "app/providers/GlobalPostbacksProvider";

import { createLazyRoute } from "../utils";

export const postbacksRoutes = [
  createLazyRoute({
    path: "/global-postbacks",
    page: "GlobalPostbacksPage",
    layout: <MainLayout title="Глобальные постбеки | Guruleads" />,
    provider: <GlobalPostbacksProvider />,
  }),
];
