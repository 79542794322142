import { makeAutoObservable, runInAction } from "mobx";

import { TrafficbackApi } from "../api";
import { ITrafficback, ITrafficbackForm, ITrafficbackParams } from "../model";

import { TogglerStore } from "shared/store/Toggler";
import { NotificationType } from "shared/ui/Notification";

const { getTrafficback, createTrafficback, updateTrafficback, deleteTrafficback } = TrafficbackApi();

export class TrafficbackStore {
  trafficback: ITrafficback | null = null;
  custom = new TogglerStore();
  loading = new TogglerStore();
  saving = new TogglerStore();

  constructor() {
    makeAutoObservable(this);
  }

  get isNew(): boolean {
    return this.trafficback === null;
  }

  get isCustom(): boolean {
    return this.custom.value;
  }

  get isLoading(): boolean {
    return this.loading.value;
  }

  get isSaving(): boolean {
    return this.saving.value;
  }

  get trafficbackForm(): ITrafficbackForm {
    if (this.trafficback) {
      return {
        id: this.trafficback.id,
        isGuru: this.custom.value ? this.trafficback.isGuru : 1,
        offerFrom: this.trafficback.offerFrom
          ? {
              value: this.trafficback.offerFrom.id,
              label: this.trafficback.offerFrom.name,
            }
          : null,
        offerTo: this.trafficback.offerTo
          ? {
              value: this.trafficback.offerTo.id,
              label: this.trafficback.offerTo.name,
            }
          : null,
        link: this.custom.value ? this.trafficback.link : "",
      };
    }
    return {
      isGuru: 1,
      offerFrom: null,
      offerTo: null,
      link: "",
    };
  }

  loadTrafficback = async (id: number) => {
    this.loading.update(true);
    const response = await getTrafficback(id);
    return runInAction(() => {
      this.loading.update(false);
      if (response.result) {
        this.trafficback = { ...response.result, id };
        return { status: "success" as NotificationType };
      } else {
        return { status: "error" as NotificationType };
      }
    });
  };

  createTrafficback = async (params: ITrafficbackParams) => {
    this.saving.update(true);
    const response = await createTrafficback(params);
    return runInAction(() => {
      this.saving.update(false);
      if (response.result) {
        return { status: "success" as NotificationType };
      }
      if (response.error) {
        const fields: Record<string, string> = {};
        if (response.error.message) {
          fields.common = response.error.message;
        }
        if (typeof response.error.data === "object") {
          Object.entries(response.error.data).forEach(([key, value]) => (fields[key] = value));
        }
        return { status: "error" as NotificationType, fields };
      }
      return { status: "error" as NotificationType };
    });
  };

  updateTrafficback = async (params: ITrafficbackParams) => {
    this.saving.update(true);
    const response = await updateTrafficback(params);
    return runInAction(() => {
      this.saving.update(false);
      if (response.result) {
        return { status: "success" as NotificationType };
      }
      if (response.error && typeof response.error.data === "object") {
        return { status: "error" as NotificationType, fields: { ...response.error.data } };
      }
      return { status: "error" as NotificationType };
    });
  };

  deleteTrafficback = async (id: number) => {
    this.saving.update(true);
    const response = await deleteTrafficback(id);
    return runInAction(() => {
      this.saving.update(false);
      if (response.result) {
        return { status: "success" as NotificationType };
      }
      return { status: "error" as NotificationType };
    });
  };

  resetTrafficback = () => {
    this.trafficback = null;
  };
}
