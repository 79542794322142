import { createContext, useContext } from "react";

import { ShopInfoStore } from "./store";

export const ShopInfoStoreContext = createContext<ShopInfoStore | null>(null);

export const useShopInfoStore = () => {
  const context = useContext(ShopInfoStoreContext);
  if (context === null) {
    throw new Error();
  }
  return context;
};
