import { FC, ReactNode } from "react";

import cn from "classnames";
import { NavLink as RouterLink } from "react-router-dom";

import classes from "./NavLink.module.scss";

interface Props {
  to: string;
  isSideMenu?: boolean;
  children: ReactNode;
  className?: string;
  isReact?: boolean;
  onClick?: () => void;
}

export const NavLink: FC<Props> = ({ to, className, children, isSideMenu = false, isReact, onClick }) => {
  if (!isReact) {
    return (
      <a className={cn(classes.link, isSideMenu && classes.profile, className)} type="button" href={to}>
        {children}
      </a>
    );
  }
  return (
    <RouterLink
      className={({ isActive }) => {
        return cn(classes.link, isSideMenu && classes.profile, isActive && classes.active, className);
      }}
      to={to}
      end
      onClick={onClick}
    >
      {children}
    </RouterLink>
  );
};
