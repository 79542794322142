import { createContext, useContext } from "react";

import { ReferalStore } from "./store";

export const ReferalStoreContext = createContext<ReferalStore | null>(null);

export const useReferalStore = () => {
  const context = useContext(ReferalStoreContext);
  if (context === null) {
    throw new Error();
  }
  return context;
};
