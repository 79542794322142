export type CurrencyCodeType = "RUB" | "USD" | "EUR" | "UAH" | "KZT" | "PHP" | "MXN";
type CurrenciesList = Record<CurrencyCodeType, string>;

export const currenciesSymbols: CurrenciesList = {
  RUB: "₽",
  USD: "$",
  EUR: "€",
  UAH: "₴",
  KZT: "₸",
  PHP: "php",
  MXN: "mxn",
} as const;
export const currenciesLabels: CurrenciesList = {
  RUB: "руб.",
  USD: "$",
  EUR: "€",
  UAH: "грн.",
  KZT: "тг.",
  PHP: "фп.",
  MXN: "мп.",
} as const;

export const getCurrencySymbol = (code: CurrencyCodeType): string => {
  return currenciesSymbols[code];
};
export const getCurrencyLabel = (code: CurrencyCodeType): string => {
  return currenciesLabels[code];
};
