import { makeAutoObservable } from "mobx";

import { TogglerModel } from "./model";

export class TogglerStore implements TogglerModel {
  value;

  constructor(value = false) {
    makeAutoObservable(this);

    this.value = value;
  }

  update = (value: boolean) => {
    this.value = value;
  };
}
