import { FC } from "react";

import { observer, useLocalObservable } from "mobx-react-lite";
import { Outlet } from "react-router-dom";

import { NotificationsSettingsStoreContext } from "entities/NotificationsSettings/context";
import { NotificationsSettingsStore } from "entities/NotificationsSettings/store";

export const NotificationsSettingsProvider: FC = observer(() => {
  const notificationsSettingsStore = useLocalObservable(() => new NotificationsSettingsStore());

  return (
    <NotificationsSettingsStoreContext.Provider value={notificationsSettingsStore}>
      <Outlet />
    </NotificationsSettingsStoreContext.Provider>
  );
});
