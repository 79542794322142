import { FC } from "react";

import { observer, useLocalObservable } from "mobx-react-lite";
import { Outlet } from "react-router-dom";

import {
  GlobalTrafficbackStoreContext,
  TrafficbackStoreContext,
  TrafficbacksStoreContext,
} from "entities/Trafficback/context";
import { GlobalTrafficbackStore, TrafficbackStore, TrafficbacksStore } from "entities/Trafficback/store";

export const TrafficbackProvider: FC = observer(() => {
  const globalTrafficbackStore = useLocalObservable(() => new GlobalTrafficbackStore());
  const trafficbackStore = useLocalObservable(() => new TrafficbackStore());
  const trafficbacksStore = useLocalObservable(() => new TrafficbacksStore());

  return (
    <TrafficbacksStoreContext.Provider value={trafficbacksStore}>
      <TrafficbackStoreContext.Provider value={trafficbackStore}>
        <GlobalTrafficbackStoreContext.Provider value={globalTrafficbackStore}>
          <Outlet />
        </GlobalTrafficbackStoreContext.Provider>
      </TrafficbackStoreContext.Provider>
    </TrafficbacksStoreContext.Provider>
  );
});
