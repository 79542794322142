import { FC } from "react";

import cn from "classnames";
import { Link as RouterLink } from "react-router-dom";

import classes from "./Link.module.scss";
import { LinkProps } from "./Link.types";

export const Link: FC<LinkProps> = ({ to, className, children, target, download, variant = "blue", onClick }) => {
  return (
    <RouterLink
      className={cn(classes.link, classes[variant], className)}
      to={to}
      onClick={onClick}
      target={target}
      download={download}
    >
      {children}
    </RouterLink>
  );
};
