import uniqid from "uniqid";

import { IPromotionParams, IShopParams, IOrderParams } from "../model";

import { request, setupData } from "shared/api";

export const ShopApi = () => {
  const dataPromotionNameConfig = setupData(uniqid("promotion"), "promotion.get-info");

  const getPromotionName = async (params: IPromotionParams) => {
    return await request([{ ...dataPromotionNameConfig, params }]);
  };

  const dataShopConfig = setupData(uniqid("promotion"), "promotion.get-shop");

  const getShop = async (params: IShopParams) => {
    return await request([{ ...dataShopConfig, params }]);
  };

  const dataOrderConfig = setupData(uniqid("order"), "promotion.create-order");

  const createOrder = async (params: IOrderParams) => {
    return await request([{ ...dataOrderConfig, params }]);
  };

  return {
    dataPromotionNameConfig,
    dataShopConfig,
    dataOrderConfig,
    getPromotionName,
    getShop,
    createOrder,
  };
};
