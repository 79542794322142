import { FC } from "react";

import { observer, useLocalObservable } from "mobx-react-lite";
import { Outlet } from "react-router-dom";

import { ProductsStoreContext } from "entities/Products/context";
import { ProductsStore } from "entities/Products/store";
import { ShopInfoStoreContext } from "entities/ShopInfo/context";
import { ShopInfoStore } from "entities/ShopInfo/store";

export const PromotionShopProvider: FC = observer(() => {
  const productStore = useLocalObservable(() => new ProductsStore());
  const userPromotionInfoStore = useLocalObservable(() => new ShopInfoStore());

  return (
    <ProductsStoreContext.Provider value={productStore}>
      <ShopInfoStoreContext.Provider value={userPromotionInfoStore}>
        <Outlet />
      </ShopInfoStoreContext.Provider>
    </ProductsStoreContext.Provider>
  );
});
