import { FC } from "react";

import cn from "classnames";

import classes from "./Status.module.scss";
import { StatusProps } from "./Status.types";

export const Status: FC<StatusProps> = ({
  className,
  children,
  color = "gray",
  showIndicator = true,
  wrap = false,
}) => {
  return (
    <span className={cn(classes.status, classes[color], showIndicator && classes.indicator, className)}>
      <span className={cn(classes.text, showIndicator && classes.indicator, wrap && classes.wrap)}>{children}</span>
    </span>
  );
};
