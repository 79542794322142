import { FC } from "react";

import { observer, useLocalObservable } from "mobx-react-lite";
import { Outlet } from "react-router-dom";

import { ProductStoreContext } from "entities/Products/context";
import { ProductStore } from "entities/Products/store";
import { ShopInfoStoreContext } from "entities/ShopInfo/context";
import { ShopInfoStore } from "entities/ShopInfo/store";
import { UserDeliveryStoreContext } from "entities/UserDelivery/context";
import { UserDeliveryStore } from "entities/UserDelivery/store";

export const PromotionOrderProvider: FC = observer(() => {
  const productStore = useLocalObservable(() => new ProductStore());
  const userDeliveryStore = useLocalObservable(() => new UserDeliveryStore());
  const shopInfoStore = useLocalObservable(() => new ShopInfoStore());

  return (
    <ProductStoreContext.Provider value={productStore}>
      <UserDeliveryStoreContext.Provider value={userDeliveryStore}>
        <ShopInfoStoreContext.Provider value={shopInfoStore}>
          <Outlet />
        </ShopInfoStoreContext.Provider>
      </UserDeliveryStoreContext.Provider>
    </ProductStoreContext.Provider>
  );
});
