import { makeAutoObservable, runInAction } from "mobx";

import { ProductsApi } from "../api";
import { IProduct, IProductCategories, IProductFilter, IProductFilterForm } from "../model";

import { PaginationStore } from "shared/store/Pagination";

const { getProducts } = ProductsApi();

export class ProductsStore {
  products: IProduct[] | undefined = undefined;
  categories: IProductCategories[] | undefined = undefined;
  filter: IProductFilter = {
    name: "",
    priceFrom: null,
    priceTo: null,
    categoryId: 0,
  };
  pagination = new PaginationStore(12);
  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  get productsCategories() {
    if (this.categories) {
      return [
        {
          id: 0,
          name: "Все",
        },
        ...this.categories,
      ];
    }
    return [];
  }

  loadProducts = async (promotionId: number | string, shopId: number | string) => {
    this.loading = true;
    const filter = { ...this.filter };
    if (typeof filter.priceFrom !== "number") {
      delete filter.priceFrom;
    }
    if (typeof filter.priceTo !== "number") {
      delete filter.priceTo;
    }
    if (!filter.categoryId) {
      delete filter.categoryId;
    }
    const result = await getProducts({
      promotionId,
      shopId,
      ...filter,
      page: this.pagination.page,
      pageSize: this.pagination.pageSize,
    });
    runInAction(() => {
      if (result && result.result) {
        this.products = [...result.result.products];
        this.pagination.updateTotalCount(result.result.pagination.totalCount);
      } else {
        this.products = [];
        this.pagination.updateTotalCount(0);
      }
      this.loading = false;
    });
  };

  updateProducts = (products: IProduct[]) => {
    this.products = [...products];
  };

  updateFilter = (newFilter: IProductFilter | IProductFilterForm) => {
    this.filter = {
      name: newFilter.name,
      priceFrom: newFilter.priceFrom !== "" && newFilter.priceFrom !== null ? Number(newFilter.priceFrom) : null,
      priceTo: newFilter.priceTo !== "" && newFilter.priceTo !== null ? Number(newFilter.priceTo) : null,
      categoryId: newFilter.categoryId,
    };
  };

  updateCategories = (categories: IProductCategories[]) => {
    this.categories = [...categories];
  };

  updateLoading = (loading: boolean) => {
    this.loading = loading;
  };
}
