import { FC } from "react";

import { BalanceProps } from "../../types";

import classes from "./Balance.module.scss";

import { Text } from "shared/ui/Text";

export const Balance: FC<BalanceProps> = ({ total, available, blocked, hold }) => {
  return (
    <>
      <Text size="lg" className={classes.text}>
        Баланс:
        <Text size="lg" bold="semi" inline>
          {total}
        </Text>
      </Text>
      <Text size="lg" className={classes.text}>
        Холд:
        <Text size="lg" bold="semi" inline>
          {hold}
        </Text>
      </Text>
      <Text size="lg" className={classes.text}>
        Заказано выплат:
        <Text size="lg" bold="semi" inline>
          {blocked}
        </Text>
      </Text>
      <Text size="lg" className={classes.text}>
        Доступно для вывода:
        <Text size="lg" bold="semi" inline>
          {available}
        </Text>
      </Text>
    </>
  );
};
