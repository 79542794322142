import { FC } from "react";

import { ReactComponent as CloseIcon } from "@material-symbols/svg-400/rounded/close.svg";
import cn from "classnames";
import { observer } from "mobx-react-lite";

import { useStore } from "entities/context";
import { NotificationProps } from "entities/Notification/model";
import { Notification } from "entities/Notification/ui";

import classes from "./NotificationCard.module.scss";

import { Button } from "shared/ui/Button";
import { Checkbox } from "shared/ui/Checkbox";

interface Props {
  notification: NotificationProps;
  preview?: boolean;
  showDelete?: boolean;
  setIds?: (ids: number[]) => void;
  ids?: number[];
}

export const NotificationCard: FC<Props> = observer(
  ({ notification, showDelete = false, preview = false, setIds, ids }) => {
    const {
      notificationStore: { read },
    } = useStore();

    return (
      <div className={cn(classes.container, preview && classes.preview)}>
        {showDelete && (
          <Checkbox
            className={classes.checkbox}
            checked={ids?.includes(notification.id)}
            onChange={() => {
              if (setIds && ids && !ids?.includes(notification.id)) {
                setIds([...ids, notification.id]);
              } else {
                setIds && ids && setIds(ids.filter((val) => val !== notification.id));
              }
            }}
          />
        )}
        <Notification data={notification} preview={preview} />
        <div className={classes.control}>
          {!notification.isRead && (
            <Button
              onClick={() => read([notification.id])}
              className={classes.button}
              size="big"
              variant="text"
              theme="secondary"
              icon={<CloseIcon className={cn(classes.icon, classes.close)} />}
            />
          )}
        </div>
      </div>
    );
  },
);
