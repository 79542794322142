import { FC, ReactNode } from "react";

import { ReactComponent as Check } from "@material-symbols/svg-400/rounded/check.svg";
import { ReactComponent as CheckIndeterminate } from "@material-symbols/svg-400/rounded/check_indeterminate_small.svg";
import cn from "classnames";

import classes from "./Checkbox.module.scss";

interface Props {
  /** Класс для кастомизации */
  className?: string;
  /** Содержимое подписи чекбокса */
  label?: ReactNode;
  /** Состояние чекбокса */
  checked?: boolean;
  /** Функция чекбокса */
  onChange?: (val: boolean) => void;
  /** Неактивный чекбокс */
  disabled?: boolean;
  /** Альтернативная иконка для активного состояния */
  isIndeterminate?: boolean;
}

export const Checkbox: FC<Props> = ({
  className,
  label = null,
  checked,
  disabled = false,
  isIndeterminate = false,
  onChange,
}) => {
  return (
    <label data-testid="checkbox-test" className={cn(classes.container, disabled && classes.disabled, className)}>
      <span className={cn(classes.checkbox, checked && classes.checked)}>
        <input
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={() => {
            onChange && onChange(!checked);
          }}
        />
        {isIndeterminate ? <CheckIndeterminate className={classes.icon} /> : <Check className={classes.icon} />}
      </span>
      {label && <span className={classes.label}>{label}</span>}
    </label>
  );
};
