import { INotificationsSettingsResponse } from "../model";

import { NotificationsSettingsApi } from "./settings";

import { requestBatch } from "shared/api";

export const notificationsSettingsBatch = async (): Promise<INotificationsSettingsResponse> => {
  const { dataChannelsConfig, dataCategoriesConfig, dataNotificationsSettingsConfig } = NotificationsSettingsApi();
  const response = await requestBatch([dataChannelsConfig, dataCategoriesConfig, dataNotificationsSettingsConfig]);
  const result: INotificationsSettingsResponse = {
    channels: null,
    categories: null,
    settings: null,
  };
  response.forEach((res) => {
    let key = null;
    if (res.id === dataChannelsConfig.id) {
      key = "channels";
    }
    if (res.id === dataCategoriesConfig.id) {
      key = "categories";
    }
    if (res.id === dataNotificationsSettingsConfig.id) {
      key = "settings";
    }
    if (key) {
      result[key as keyof INotificationsSettingsResponse] = { ...res };
    }
  });
  return result;
};
