import uniqid from "uniqid";

import { request, setupData } from "shared/api";

export const NotificationApi = () => {
  const dataNotificationConfig = setupData(uniqid("all-notify"), "profile.get-notification-all");

  const getNotifications = async (params: any) => {
    return await request([{ ...dataNotificationConfig, params: params }]);
  };

  const dataNotificationCountConfig = setupData(uniqid("notify-count"), "profile.get-notification-count");

  const getNotificationCount = async () => {
    return await request([{ ...dataNotificationCountConfig }]);
  };

  const readAllNotificationsConfig = setupData(uniqid("read-all-notify"), "profile.notification-mark-read-all");

  const readAllNotifications = async () => {
    return await request([{ ...readAllNotificationsConfig }]);
  };

  const readNotificationConfig = setupData(uniqid("read-notify"), "profile.notification-mark-read");

  const readNotification = async (params: Record<"ids", number[]>) => {
    return await request([{ ...readNotificationConfig, params: params }]);
  };

  return {
    dataNotificationConfig,
    dataNotificationCountConfig,
    readAllNotificationsConfig,
    readNotificationConfig,
    getNotifications,
    getNotificationCount,
    readAllNotifications,
    readNotification,
  };
};
