import uniqid from "uniqid";

import { ITrafficbacksParams } from "../model";

import { request, setupData } from "shared/api";

export const TrafficbacksApi = () => {
  const dataTrafficbacksConfig = setupData(uniqid("trafficback"), "trafficback.get-trafficbacks-offer");

  const getTrafficbacks = async (params: ITrafficbacksParams) => {
    return await request([{ ...dataTrafficbacksConfig, params }]);
  };

  return {
    dataTrafficbacksConfig,
    getTrafficbacks,
  };
};
