import { FC, ReactNode } from "react";

import { observer, useLocalObservable } from "mobx-react-lite";
import { Outlet } from "react-router-dom";

import { GlobalPostbacksStoreContext } from "entities/Postback/context";
import { GlobalPostbacksStore } from "entities/Postback/store";

export const GlobalPostbacksProvider: FC = observer(() => {
  const store = useLocalObservable(() => new GlobalPostbacksStore());
  return (
    <GlobalPostbacksStoreContext.Provider value={store}>
      <Outlet />
    </GlobalPostbacksStoreContext.Provider>
  );
});
