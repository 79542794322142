import { FC } from "react";

import cn from "classnames";

import classes from "./Confirm.module.scss";
import { ConfirmModalProps } from "./Confirm.types";

import { BaseModal } from "shared/ui/BaseModal";
import { BasePortal } from "shared/ui/BasePortal";

const portalNode = document.createElement("div");
portalNode.setAttribute("id", "confirm-portal");
portalNode.setAttribute("data-testid", "confirm-portal");

/**
 * Компонент реализован на основе компонента модального окна.
 * Используются все пропы модального окна.
 *
 * Добавлены пропы `img` и `imgClassName` и изменена верстка контента модального окна.
 */
export const Confirm: FC<ConfirmModalProps> = ({
  children,
  img,
  title,
  dialogClassName,
  titleClassName,
  headerClassName,
  bodyClassName,
  footerClassName,
  imgClassName,
  ...props
}) => {
  return (
    <BasePortal portalNode={portalNode}>
      <BaseModal
        dialogClassName={cn(classes.confirm, dialogClassName)}
        headerClassName={cn(classes["confirm-header"], headerClassName)}
        bodyClassName={cn(classes["confirm-body"], bodyClassName)}
        title=""
        footerClassName={cn(classes["confirm-footer"], footerClassName)}
        {...props}
      >
        {img && <div className={cn(classes["confirm-img"], imgClassName)}>{img}</div>}
        {title && <h3 className={cn(classes["confirm-title"], titleClassName)}>{title}</h3>}
        {children && <div className={classes["confirm-content"]}>{children}</div>}
      </BaseModal>
    </BasePortal>
  );
};
