import { makeAutoObservable } from "mobx";

import { BackgroundProps } from "./model";

export class SettingsStore {
  background: BackgroundProps | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  updateBackground = (val: BackgroundProps) => {
    this.background = val;
  };
}
