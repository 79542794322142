import { makeAutoObservable, runInAction } from "mobx";

import { ShopApi } from "./api";
import { IShopInfo, IPromotionInfo, IOrderParams } from "./model";

import { NotificationType } from "shared/ui/Notification";
import { formatNumber } from "shared/utils";

const { createOrder } = ShopApi();

export class ShopInfoStore {
  promotion: IPromotionInfo | undefined = undefined;
  shop: IShopInfo | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get promotionId(): number | string | null {
    if (this.promotion) return this.promotion.id;
    return null;
  }

  get promotionName(): string {
    return this.promotion?.name || "";
  }

  get shopId(): number | string | null {
    if (this.shop) return this.shop.id;
    return null;
  }

  get shopBalance(): string {
    if (this.shop) {
      return `${formatNumber(this.shop.balance)} ${this.shop.currency}`;
    }
    return "0";
  }

  get orderCount(): string {
    return formatNumber(this.shop?.count || 0);
  }

  get shopCurrency(): string {
    return this.shop?.currency || "";
  }

  createOrder = async (order: IOrderParams) => {
    const response = await createOrder({ ...order });
    return runInAction(() => {
      if (response.result) {
        return { status: "success" as NotificationType, id: response.result.id };
      }
      if (response.error) {
        if (typeof response.error.data === "object") {
          return { status: "error" as NotificationType, fields: { ...response.error.data } };
        }
      }
      return { status: "error" as NotificationType };
    });
  };

  updatePromotion = (promotion: IPromotionInfo) => {
    this.promotion = { ...promotion };
  };

  updateShop = (shop: IShopInfo) => {
    this.shop = { ...shop };
  };
}
