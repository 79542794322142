import { FC } from "react";

import DOMPurify from "dompurify";

import classes from "./HtmlComponent.module.scss";

interface Props {
  innerHtml: Element;
}

export const HtmlComponent: FC<Props> = ({ innerHtml }) => {
  return <div className={classes.container} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(innerHtml) }} />;
};
