import { MainLayout } from "app/layouts/MainLayout";
import { ProfileLayout } from "app/layouts/ProfileLayout";

import { createLazyRoute } from "../utils";

export const contractsRoutes = [
  createLazyRoute({
    path: "/profile/contracts",
    page: "InProgress",
    layout: <ProfileLayout title="Договоры | Guruleads" />,
  }),
  createLazyRoute({
    path: "/profile/contracts/:id",
    page: "InProgress",
    layout: <MainLayout title="Договоры | Guruleads" />,
  }),
];
