import { FC, ReactNode } from "react";

import cn from "classnames";

import classes from "./Container.module.scss";

interface Props {
  /** Контент контейнера */
  children: ReactNode;
  /** Кастомные классы */
  className?: string;
  /** Контейнер без максимальной ширины */
  fluid?: boolean;
  /** Присутствует фон */
  withBackground?: boolean;
}

/**
 * Ширина контейнера для разных брейкпоинтов:
 * - `phone` или `sm` -- 100%
 * - `small-tablet` или `md` -- 540px
 * - `tablet` или `lg` -- 720px
 * - `laptop` или `xl` -- 960px
 * - `desktop` или `xxl` -- 1140px
 * - больше `xxl` -- 1320px
 *
 * Опция `fluid` отключает максимальную ширину для контейнера, он всегда растягивается на 100%.
 *
 * Для дополнительной стилизации контейнера можно использовать проп `className`.
 */
export const Container: FC<Props> = ({ children, className = "", fluid = false, withBackground = false }) => {
  return (
    <div
      className={cn(className, classes.container, fluid && classes.fluid, withBackground && classes["with-background"])}
    >
      {children}
    </div>
  );
};
