import { makeAutoObservable, runInAction } from "mobx";

import { NotificationApi } from "./api";
import { NotificationProps } from "./model";

const { getNotifications, readNotification, readAllNotifications } = NotificationApi();

export class NotificationPreviewStore {
  previewNotifications: NotificationProps[] | null = null;
  count = 0;
  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  loadPreviewNotifications = async () => {
    this.loading = true;
    const result = await getNotifications({ isRead: false, perPage: 4, page: 1 });

    runInAction(() => {
      if (result.result) {
        this.update(result.result.messages ?? []);
        this.updateCount(result.result.pagination.totalCount || 0);
      } else {
        this.update([]);
        this.updateCount(0);
      }
      this.loading = false;
    });
  };

  update = (val: NotificationProps[]) => {
    if (Array.isArray(val)) {
      this.previewNotifications = [...val];
    }
  };

  updateCount = (val: number) => {
    this.count = val;
  };

  read = async (ids: number[]) => {
    const result = await readNotification({ ids });
    if (result.result) {
      this.loadPreviewNotifications();
    }
  };

  readAll = async () => {
    const result = await readAllNotifications();
    if (result.result) {
      this.loadPreviewNotifications();
    }
  };
}
