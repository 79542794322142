import { FC, useEffect, useRef, MouseEventHandler } from "react";

import cn from "classnames";
import { observer } from "mobx-react-lite";
import { CSSTransition } from "react-transition-group";
import { useEventListener } from "usehooks-ts";

import { BasePortal } from "../BasePortal";

import { DrawerProvider } from "./context";
import classes from "./Drawer.module.scss";
import { DrawerContextValue, DrawerProps } from "./Drawer.types";

const portalNode = document.createElement("div");
portalNode.setAttribute("id", "drawer-portal");

export const Drawer: FC<DrawerProps> = observer(
  ({ children, className, open = false, theme = "light", position = "left", setOpen }) => {
    const ref = useRef(null);
    const closeDrawer = () => setOpen && setOpen(false);
    const onClickHandler: MouseEventHandler<HTMLDivElement> = (e) => {
      if (e.target === ref.current) {
        closeDrawer();
      }
    };
    const ctx: DrawerContextValue = {
      close: closeDrawer,
    };

    useEventListener("keydown", (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        closeDrawer();
      }
    });
    useEffect(() => {
      if (open) {
        document.body.classList.add(classes.overflow);
      } else {
        document.body.classList.remove(classes.overflow);
      }
    }, [open]);

    return (
      <BasePortal portalNode={portalNode}>
        <CSSTransition
          in={open}
          timeout={500}
          nodeRef={ref}
          classNames={{
            enter: classes[`drawer-enter`],
            enterActive: classes[`drawer-enter-active`],
            exit: classes[`drawer-exit`],
            exitActive: classes[`drawer-exit-active`],
          }}
          unmountOnExit
        >
          <DrawerProvider value={ctx}>
            <div
              data-testid="drawer-test"
              ref={ref}
              className={cn(className, classes.container, classes[theme])}
              onClick={onClickHandler}
            >
              <div data-testid="drawer-content-test" className={cn(classes.content, classes[position])}>
                {children}
              </div>
            </div>
          </DrawerProvider>
        </CSSTransition>
      </BasePortal>
    );
  },
);
