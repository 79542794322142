import uniqid from "uniqid";

import { ITrafficbacksOffersParams } from "../model";

import { request, setupData } from "shared/api";

export const OffersApi = () => {
  const dataOffersFromConfig = setupData(uniqid("trafficback-offers-from"), "trafficback.get-offers-from");

  const getOffersFrom = async (params: ITrafficbacksOffersParams) => {
    const response = await request([{ ...dataOffersFromConfig, params }]);
    if (response.result && response.result.items && !Array.isArray(response.result.items)) {
      response.result.items = Object.entries(response.result.items).map(([key, value]) => ({
        value: Number(key),
        label: value,
      }));
    }
    return response;
  };
  const dataOffersToConfig = setupData(uniqid("trafficback-offers-to"), "trafficback.get-offers-to");

  const getOffersTo = async (params: ITrafficbacksOffersParams) => {
    const response = await request([{ ...dataOffersToConfig, params }]);
    if (response.result && response.result.items && !Array.isArray(response.result.items)) {
      response.result.items = Object.entries(response.result.items).map(([key, value]) => ({
        value: Number(key),
        label: value,
      }));
    }
    return response;
  };

  return {
    dataOffersFromConfig,
    dataOffersToConfig,
    getOffersFrom,
    getOffersTo,
  };
};
