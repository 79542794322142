import { FC } from "react";

import { ReactComponent as CloseIcon } from "@material-symbols/svg-400/rounded/close.svg";
import cn from "classnames";

import { BurgerButtonProps } from "../../types";

import classes from "./BurgerButton.module.scss";

export const BurgerButton: FC<BurgerButtonProps> = ({ children, className, open = false, setOpen }) => {
  return (
    <button
      type="button"
      data-testid="burger-button-test"
      onClick={() => setOpen && setOpen(!open)}
      className={cn(classes.burger, className)}
    >
      {open ? <CloseIcon className={classes.close} /> : children}
    </button>
  );
};
