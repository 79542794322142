import { FC, ReactNode, useEffect } from "react";

import { createPortal } from "react-dom";

interface Props {
  /** Компонент, который будет рендериться в портале */
  children?: ReactNode;
  /**
   * HTML элемент, в котором будет рендериться компонент.
   * Если не указан - компоненты добавляются в конец `document.body`.
   */
  portalNode?: Element;
}

export const BasePortal: FC<Props> = ({ children, portalNode }) => {
  useEffect(() => {
    if (portalNode) {
      document.body.append(portalNode);
      return () => portalNode.remove();
    }
  });
  return createPortal(children, portalNode || document.body);
};
