import { ReactNode } from "react";

import { RouteObject } from "react-router-dom";
import uniqid from "uniqid";

import { ErrorBoundaryPage } from "pages/ErrorBoundaryPage";

type DefaultRouteObject = RouteObject & {
  /** Разметка страницы */
  layout?: ReactNode | null;
  /** Контекст со стором */
  provider?: ReactNode | null;
};

export const createRoute = (props: DefaultRouteObject) => {
  const { path, element, children, layout, provider } = props;

  return {
    id: uniqid(),
    key: uniqid(),
    component: provider,
    children: [
      {
        id: uniqid(),
        key: uniqid(),
        element: layout,
        children: [
          {
            id: uniqid(),
            key: uniqid(),
            path: path,
            element: element,
            errorElement: <ErrorBoundaryPage />,
            children: children,
          },
        ],
      },
    ],
  };
};

type LazyRouteObject = RouteObject & {
  /** Название папки со страницей, по нему будет строиться отложенный импорт */
  page: string;
  /** Разметка страницы */
  layout?: ReactNode | null;
  /** Контекст со стором */
  provider?: ReactNode | null;
};

export const createLazyRoute = (props: LazyRouteObject) => {
  const { path, page, layout, provider } = props;

  return {
    id: uniqid(),
    key: uniqid(),
    element: provider,
    children: [
      {
        id: uniqid(),
        key: uniqid(),
        element: layout,
        children: [
          {
            id: uniqid(),
            key: uniqid(),
            path: path,
            errorElement: <ErrorBoundaryPage />,
            lazy: async () => {
              const pageToLoad = await import(`pages/${page}`);

              return { Component: pageToLoad[Object.keys(pageToLoad)[0]] };
            },
          },
        ],
      },
    ],
  };
};
