import { FC, ReactNode } from "react";

import { Link } from "react-router-dom";

export interface BaseButtonProps {
  /** Классы */
  className?: string;
  /** Текст кнопки */
  children?: ReactNode;
  /** Функция кнопки */
  onClick?: () => void;
  /** Неактивная */
  disabled?: boolean;
  /** Тип кнопки */
  type?: "button" | "submit" | "reset" | undefined;
  /** Ссылка */
  href?: string;
  /** Открытие ссылки */
  target?: "_self" | "_blank" | "_parent" | "_top";
  /** Скачать */
  download?: boolean;
  /** ID для тестирования */
  dataTestid?: string;
}

export const BaseButton: FC<BaseButtonProps> = ({
  className,
  children,
  onClick,
  disabled,
  type,
  href,
  target,
  download,
  dataTestid,
}) => {
  if (href) {
    return (
      <Link
        className={className}
        onClick={onClick}
        to={href}
        target={target}
        download={download}
        data-testid={dataTestid}
      >
        {children}
      </Link>
    );
  }

  return (
    <button data-testid={dataTestid} type={type} className={className} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
};
