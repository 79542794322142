import { FC } from "react";

import { observer, useLocalObservable } from "mobx-react-lite";
import { Outlet } from "react-router-dom";

import { TFAStoreContext } from "entities/UserTFA/context";
import { TFAStore } from "entities/UserTFA/store";

export const TFAProvider: FC = observer(() => {
  const tfaStore = useLocalObservable(() => new TFAStore());

  return (
    <TFAStoreContext.Provider value={tfaStore}>
      <Outlet />
    </TFAStoreContext.Provider>
  );
});
