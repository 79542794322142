import { makeAutoObservable, runInAction } from "mobx";

import { TrafficbacksApi } from "../api";
import { ITrafficbackItem, ITrafficbacksParams } from "../model";

import { PaginationStore } from "shared/store/Pagination";
import { SortingStore } from "shared/store/Sorting";
import { TogglerStore } from "shared/store/Toggler";

const { getTrafficbacks } = TrafficbacksApi();

export class TrafficbacksStore {
  trafficbacks: ITrafficbackItem[] | null = null;
  pagination = new PaginationStore(10);
  sorting = new SortingStore("createdAt", 3);
  loading = new TogglerStore();

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading(): boolean {
    return this.loading.value;
  }

  get params(): ITrafficbacksParams {
    return {
      sort: this.sorting.sort,
      page: this.pagination.page,
      pageSize: this.pagination.pageSize,
    };
  }

  loadTrafficbacks = async () => {
    this.loading.update(true);
    const response = await getTrafficbacks(this.params);
    runInAction(() => {
      if (response.result) {
        this.trafficbacks = response.result.items;
        this.pagination.updateTotalCount(response.result.pagination.totalCount);
      } else {
        this.trafficbacks = [];
        this.pagination.updateTotalCount(0);
      }
      this.loading.update(false);
    });
  };

  updateTrafficbacks = (items: ITrafficbackItem[]) => {
    this.trafficbacks = [...items];
  };
}
