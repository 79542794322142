import { makeAutoObservable, runInAction } from "mobx";

import { OffersApi } from "../api";

import { PaginationStore } from "shared/store/Pagination";
import { TogglerStore } from "shared/store/Toggler";
import { DefaultOption } from "shared/ui/Select";

const { getOffersFrom, getOffersTo } = OffersApi();

export class OffersDictionaryStore {
  loading = new TogglerStore();
  offers: DefaultOption[] | null = null;
  pagination = new PaginationStore(10);

  constructor() {
    makeAutoObservable(this);
  }

  loadOffers = async (from = true, term = "") => {
    this.loading.update(true);
    const params = {
      term,
      page: this.pagination.page,
      pageSize: this.pagination.pageSize,
    };
    const response = await (from ? getOffersFrom(params) : getOffersTo(params));
    runInAction(() => {
      if (response.result) {
        this.updateOffers(response.result.items);
        this.pagination.updateTotalCount(response.result.pagination.totalCount);
      }
      this.loading.update(false);
    });
  };

  updateOffers = (values: DefaultOption[]) => {
    if (this.offers && this.pagination.page !== 1) {
      this.offers = [...this.offers, ...values];
    } else {
      this.offers = [...values];
    }
  };

  resetOffers = () => {
    this.offers = null;
    this.loading.update(false);
    this.pagination.updatePage(1);
  };
}
