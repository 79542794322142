import { FC } from "react";

import cn from "classnames";

import { TooltipProps } from "../../types";
import { Balance } from "../Balance";

import classes from "./Tooltip.module.scss";

export const Tooltip: FC<TooltipProps> = ({ className, children, total, available, blocked, hold }) => {
  return (
    <div className={cn(classes.container, className)}>
      <div className={classes.target}>{children}</div>
      <div className={classes.tooltip}>
        <Balance available={available} blocked={blocked} hold={hold} total={total} />
      </div>
    </div>
  );
};
