import uniqid from "uniqid";

import { request, setupData } from "shared/api";

export const SettingsApi = () => {
  /** Акционный фон для ЛКВ */
  const dataBackgroundConfig = setupData(uniqid("bg"), "profile.get-background");

  const getBackground = async () => {
    return await request([{ ...dataBackgroundConfig }]);
  };

  return {
    dataBackgroundConfig,
    getBackground,
  };
};
