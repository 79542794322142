import { makeAutoObservable, runInAction } from "mobx";

import { ProductsApi } from "../api";
import { IProduct } from "../model";

const { getProduct } = ProductsApi();

export class ProductStore {
  product: IProduct | undefined = undefined;
  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  get productSizes() {
    if (this.product && this.product.sizes) {
      return this.product.sizes.map((size) => ({ value: size, label: size }));
    }
    return [];
  }

  get productCounts() {
    if (this.product) {
      const length = this.product.limit || this.product.remains || 0;
      return Array.from({ length }, (_, i) => {
        return { value: i + 1, label: (i + 1).toString() };
      });
    }
    return [{ value: 1, label: "1" }];
  }

  get defaultCount() {
    return this.productCounts[0];
  }

  get needsDelivery() {
    return this.product?.needsDelivery ?? false;
  }

  loadProduct = async (id: number | string, promotionId: number | string, shopId: number | string) => {
    this.loading = true;
    const result = await getProduct({ id, promotionId, shopId });
    runInAction(() => {
      if (result && result.result) {
        this.product = { ...result.result };
      }
      this.loading = false;
    });
  };

  updateProduct = (product?: IProduct) => {
    this.product = product;
  };

  updateLoading = (loading: boolean) => {
    this.loading = loading;
  };
}
