import { MainLayout } from "app/layouts/MainLayout";
import { Navigate } from "react-router-dom";

import { Page404 } from "pages/404";

import { createRoute, createLazyRoute } from "../utils";

export const mainRoutes = [
  createRoute({ path: "/", element: <Navigate to="/dashboard" /> }),
  createLazyRoute({ path: "/dashboard", page: "InProgress", layout: <MainLayout title="Главная | Guruleads" /> }),
  createRoute({
    path: "*",
    element: <Page404 />,
    layout: <MainLayout title="Страница не найдена | Guruleads" showFooter={false} />,
  }),
];
