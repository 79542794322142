export const getDate = (newDate: Date): string => {
  const date = new Date(newDate);
  const timeStr = new Intl.DateTimeFormat("ru-RU", {
    hour: "numeric",
    minute: "numeric",
  }).format(date);
  const dateStr = new Intl.DateTimeFormat("ru-RU", {
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(date);
  return `${timeStr}, ${dateStr.slice(0, -3)}`;
};

export const getNumericDate = (newDate: Date): string => {
  const date = new Date(newDate);
  const timeStr = new Intl.DateTimeFormat("ru-RU", {
    hour: "numeric",
    minute: "numeric",
  }).format(date);
  const dateStr = new Intl.DateTimeFormat("ru-RU", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  }).format(date);
  return `${timeStr}, ${dateStr}`;
};
