import uniqid from "uniqid";

import { request, setupData } from "shared/api";

export const NotificationsSettingsApi = () => {
  const dataChannelsConfig = setupData(uniqid("notify-channels"), "profile.get-notification-channels-all");

  const getChannels = async () => {
    return await request([{ ...dataChannelsConfig }]);
  };

  const dataCategoriesConfig = setupData(uniqid("notify-categories"), "profile.get-notification-categories-all");

  const getCategories = async () => {
    return await request([{ ...dataCategoriesConfig }]);
  };

  const dataNotificationsSettingsConfig = setupData(uniqid("notify-settings"), "profile.get-notification-triggers-all");

  const getNotificationsSettings = async () => {
    return await request([{ ...dataNotificationsSettingsConfig }]);
  };

  const dataUpdateNotificationsSettingConfig = setupData(
    uniqid("update-setting"),
    "profile.update-notification-subscription",
  );

  const updateNotificationsSetting = async (params: any) => {
    return await request([{ ...dataUpdateNotificationsSettingConfig, params }]);
  };

  return {
    dataChannelsConfig,
    dataCategoriesConfig,
    dataNotificationsSettingsConfig,
    dataUpdateNotificationsSettingConfig,
    getChannels,
    getCategories,
    getNotificationsSettings,
    updateNotificationsSetting,
  };
};
