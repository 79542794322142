import { MainLayout } from "app/layouts/MainLayout";
import { PromotionOrderProvider } from "app/providers/PromotionOrderProvider";
import { PromotionShopProvider } from "app/providers/PromotionShopProvider";

import { createLazyRoute } from "../utils";

export const promotionsRoutes = [
  createLazyRoute({
    path: "/promotions/:promotionId/shop",
    page: "PromotionShopPage",
    layout: <MainLayout title="Магазин | Guruleads" />,
    provider: <PromotionShopProvider />,
  }),
  createLazyRoute({
    path: "/promotions/:promotionId/shop/:productId/create-order",
    page: "PromotionCheckoutPage",
    layout: <MainLayout title="Оформление заказа | Guruleads" />,
    provider: <PromotionOrderProvider />,
  }),
];
