import { makeAutoObservable, runInAction } from "mobx";

import { GlobalTrafficbackApi } from "../api";
import { IGlobalTrafficback, IGlobalTrafficbackForm, IGlobalTrafficbackParams } from "../model";

import { TogglerStore } from "shared/store/Toggler";
import { NotificationType } from "shared/ui/Notification";

const { getGlobalTrafficback, createGlobalTrafficback, deleteGlobalTrafficback } = GlobalTrafficbackApi();

export class GlobalTrafficbackStore {
  globalTrafficback: IGlobalTrafficback | null = null;
  custom = new TogglerStore();
  updating = new TogglerStore();
  saving = new TogglerStore();

  constructor() {
    makeAutoObservable(this);
  }

  get isNew(): boolean {
    return this.globalTrafficback === null;
  }

  get isCustom(): boolean {
    return this.custom.value;
  }

  get isUpdating(): boolean {
    return this.updating.value;
  }

  get isSaving(): boolean {
    return this.saving.value;
  }

  get globalTrafficbackForm(): IGlobalTrafficbackForm {
    if (this.globalTrafficback) {
      return {
        isGuru: this.custom.value ? this.globalTrafficback.isGuru : 1,
        offer: this.globalTrafficback.offer
          ? {
              value: this.globalTrafficback.offer.id,
              label: this.globalTrafficback.offer.name,
            }
          : null,
        link: this.custom.value ? this.globalTrafficback.link : "",
      };
    }
    return {
      isGuru: 1,
      offer: null,
      link: "",
    };
  }

  loadGlobalTrafficback = async () => {
    this.updating.update(true);
    const response = await getGlobalTrafficback();
    return runInAction(() => {
      this.updating.update(false);
      if (!response.error) {
        if (response.result && !Array.isArray(response.result)) this.globalTrafficback = { ...response.result };
        else this.globalTrafficback = null;
        return { status: "success" as NotificationType };
      }
      return { status: "error" as NotificationType };
    });
  };

  createGlobalTrafficback = async (params: IGlobalTrafficbackParams) => {
    this.saving.update(true);
    const response = await createGlobalTrafficback(params);
    return runInAction(() => {
      this.saving.update(false);
      if (response.result) {
        this.loadGlobalTrafficback();
        return { status: "success" as NotificationType };
      }
      if (response.error) {
        const fields: Record<string, string> = {};
        if (response.error.message) {
          fields.common = response.error.message;
        }
        if (typeof response.error.data === "object") {
          Object.entries(response.error.data).forEach(([key, value]) => (fields[key] = value));
        }
        return { status: "error" as NotificationType, fields };
      }
      return { status: "error" as NotificationType };
    });
  };

  deleteGlobalTrafficback = async () => {
    this.saving.update(true);
    const response = await deleteGlobalTrafficback();
    return runInAction(() => {
      this.saving.update(false);
      if (response.result) {
        this.loadGlobalTrafficback();
        return { status: "success" as NotificationType };
      }
      return { status: "error" as NotificationType };
    });
  };

  updateGlobalTrafficback = (values: IGlobalTrafficback) => {
    this.globalTrafficback = { ...values };
  };
}
