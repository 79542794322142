import { makeAutoObservable, runInAction } from "mobx";

import { TFAApi } from "./api";
import { TFAStatusType } from "./model";

const { getTfaStatus } = TFAApi();

export class TFAStore {
  tfa: TFAStatusType | null = null;
  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  loadStatus = async () => {
    if (!this.tfa) {
      this.loading = true;
      const result = await getTfaStatus();
      runInAction(() => {
        if (result && result.result) {
          this.tfa = result.result.status;
        }
        this.loading = false;
      });
    }
  };

  updateTFA = (val: TFAStatusType) => {
    this.tfa = val;
  };
}
