import { createContext, useContext } from "react";

import { NotificationsSettingsStore } from "./store";

export const NotificationsSettingsStoreContext = createContext<NotificationsSettingsStore | null>(null);

export const useNotificationsSettingsStore = () => {
  const context = useContext(NotificationsSettingsStoreContext);
  if (context === null) {
    throw new Error();
  }
  return context;
};
