import uniqid from "uniqid";

import { ITrafficbackParams } from "../model";

import { request, setupData } from "shared/api";

export const TrafficbackApi = () => {
  const dataTrafficbackConfig = setupData(uniqid("trafficback"), "trafficback.get-trafficback-offer");

  const getTrafficback = async (id: number) => {
    return await request([{ ...dataTrafficbackConfig, params: { id } }]);
  };

  const dataTrafficbackCreateConfig = setupData(uniqid("trafficback-create"), "trafficback.create-trafficback-offer");

  const createTrafficback = async (params: ITrafficbackParams) => {
    return await request([{ ...dataTrafficbackCreateConfig, params }]);
  };

  const dataTrafficbackUpdateConfig = setupData(uniqid("trafficback-update"), "trafficback.update-trafficback-offer");

  const updateTrafficback = async (params: ITrafficbackParams) => {
    return await request([{ ...dataTrafficbackUpdateConfig, params }]);
  };

  const dataTrafficbackDeleteConfig = setupData(uniqid("trafficback-delete"), "trafficback.delete-trafficback-offer");

  const deleteTrafficback = async (id: number) => {
    return await request([{ ...dataTrafficbackDeleteConfig, params: { id } }]);
  };

  return {
    dataTrafficbackConfig,
    dataTrafficbackCreateConfig,
    dataTrafficbackUpdateConfig,
    dataTrafficbackDeleteConfig,
    getTrafficback,
    createTrafficback,
    updateTrafficback,
    deleteTrafficback,
  };
};
