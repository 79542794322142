import uniqid from "uniqid";

import { IProductsRequestParams, IProductRequestParams } from "../model";

import { request, setupData } from "shared/api";

export const ProductsApi = () => {
  const dataProductsConfig = setupData(uniqid("products"), "promotion.get-products");

  const getProducts = async (params: IProductsRequestParams) => {
    return await request([{ ...dataProductsConfig, params }]);
  };

  const dataProductConfig = setupData(uniqid("product"), "promotion.get-product");

  const getProduct = async (params: IProductRequestParams) => {
    return await request([{ ...dataProductConfig, params }]);
  };

  const dataCategoriesConfig = setupData(uniqid("categories"), "promotion.get-product-categories");

  const getCategories = async (params: Omit<IProductRequestParams, "id">) => {
    return await request([{ ...dataCategoriesConfig, params }]);
  };

  return {
    dataProductsConfig,
    dataProductConfig,
    dataCategoriesConfig,
    getProducts,
    getProduct,
    getCategories,
  };
};
