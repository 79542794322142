import { FC, useState } from "react";

import { ReactComponent as AccountIcon } from "@material-symbols/svg-400/rounded/account_circle.svg";
import { ReactComponent as ArrowIcon } from "@material-symbols/svg-400/rounded/arrow_right_alt.svg";
import { ReactComponent as InfoIcon } from "@material-symbols/svg-400/rounded/info.svg";
import cn from "classnames";
import { observer } from "mobx-react-lite";

import { useStore } from "entities/context";
import { SYSTEMS } from "entities/User/model";

import { Balance } from "../Balance";
import { BurgerButton } from "../BurgerButton";
import { Notifications } from "../Notifications";
import { Tooltip } from "../Tooltip";

import classes from "./RightSide.module.scss";

import { IHeaderRightSize } from "widgets/Header/types";

import { Drawer } from "shared/ui/Drawer";
import { Link } from "shared/ui/Link";
import { Text } from "shared/ui/Text";

export const RightSide: FC<IHeaderRightSize> = observer(
  ({ openProfile, openNotifications, handleOpenProfile, handleOpenNotifications }) => {
    const {
      userStore: { userSystem, userFullName, userTotalBalance, userAvailableBalance, userBlockedBalance, userHold },
    } = useStore();

    return (
      <>
        <div className={classes.right}>
          {userSystem !== SYSTEMS.yoco && (
            <Notifications open={openNotifications} handleOpen={handleOpenNotifications} />
          )}
          <Link to={"/profile"} className={classes.name}>
            {userFullName}
          </Link>
          <Tooltip
            total={userTotalBalance}
            available={userAvailableBalance}
            blocked={userBlockedBalance}
            hold={userHold}
          >
            <div data-testid="balance-header" className={classes.balance}>
              {userTotalBalance}
            </div>
            <InfoIcon className={classes["info-icon"]} />
          </Tooltip>
          <BurgerButton className={classes.profile} open={openProfile} setOpen={() => handleOpenProfile(!openProfile)}>
            <AccountIcon className={classes.account} />
          </BurgerButton>
        </div>
        <Drawer className={classes.drawer} theme="dark" position="right" open={openProfile} setOpen={handleOpenProfile}>
          <div className={classes["profile-info"]}>
            <Link
              onClick={() => handleOpenProfile(false)}
              to={"/profile"}
              className={cn(classes.text, classes.link, classes["text-with-arrow"])}
            >
              <Text bold="semi" height="small" size="lg" className={classes.name}>
                <AccountIcon className={classes.profile} />
                {userFullName}
              </Text>
              <ArrowIcon className={classes.arrow} />
            </Link>
            <Balance
              available={userAvailableBalance}
              blocked={userBlockedBalance}
              hold={userHold}
              total={userTotalBalance}
            />
          </div>
        </Drawer>
      </>
    );
  },
);
