import { makeAutoObservable } from "mobx";

import { ReferalProgramProps } from "./model";

export class ReferalStore {
  referal: ReferalProgramProps | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  updateReferal = (val: ReferalProgramProps) => {
    this.referal = val;
  };
}
