import { CSSProperties } from "react";

import cn from "classnames";

import {
  ICol,
  IRow,
  DirectionType,
  AlignsType,
  JustifyType,
  BreackpointsType,
  SCSSModuleType,
  TextAlignType,
  SpanType,
  OffsetType,
  OrderType,
} from "./types";

type ClassesType = "grid" | "col";

const getBpClasses = (
  classes: SCSSModuleType,
  props: ICol | Omit<IRow, "gap">,
  type: ClassesType,
  prefix?: BreackpointsType,
): string => {
  const innerPrefix = prefix ? "-" + prefix : "";
  return Object.entries(props)
    .reduce((result, [key, value]) => {
      if (value !== undefined) {
        if (key !== "wrap") {
          result.push(classes[`${type}${innerPrefix}-${key}-${value}`]);
        } else if (!value) {
          result.push(classes[`${type}${innerPrefix}-no-wrap`]);
        }
      }
      return result;
    }, [] as string[])
    .join(" ");
};
const getClasses = (
  classes: SCSSModuleType,
  props: ICol | Omit<IRow, "gap">,
  xs: ICol | Omit<IRow, "gap">,
  sm: ICol | Omit<IRow, "gap">,
  md: ICol | Omit<IRow, "gap">,
  lg: ICol | Omit<IRow, "gap">,
  xl: ICol | Omit<IRow, "gap">,
  xxl: ICol | Omit<IRow, "gap">,
  type: ClassesType,
): string => {
  return cn(
    getBpClasses(classes, props, type),
    getBpClasses(classes, xs, type, "xs"),
    getBpClasses(classes, sm, type, "sm"),
    getBpClasses(classes, md, type, "md"),
    getBpClasses(classes, lg, type, "lg"),
    getBpClasses(classes, xl, type, "xl"),
    getBpClasses(classes, xxl, type, "xxl"),
  );
};

export const getColClasses = (
  classes: SCSSModuleType,
  props: ICol,
  xs: ICol,
  sm: ICol,
  md: ICol,
  lg: ICol,
  xl: ICol,
  xxl: ICol,
): string => getClasses(classes, props, xs, sm, md, lg, xl, xxl, "col");

export const getGridClasses = (
  classes: SCSSModuleType,
  props: IRow,
  xs: IRow,
  sm: IRow,
  md: IRow,
  lg: IRow,
  xl: IRow,
  xxl: IRow,
): string => getClasses(classes, props, xs, sm, md, lg, xl, xxl, "grid");

const getGapBpStyles = ({ gap }: IRow, prefix?: BreackpointsType): CSSProperties => {
  if (gap) {
    const innerPrefix = prefix ? "-" + prefix : "";
    const rowGap = (Array.isArray(gap) ? gap[0] : gap) + "px";
    const columnGap = (Array.isArray(gap) ? gap[1] : gap) + "px";
    return {
      [`--row${innerPrefix}-gap`]: rowGap,
      [`--column${innerPrefix}-gap`]: columnGap,
    };
  }
  return {};
};

export const getGapStyles = (
  props: IRow,
  xs: IRow,
  sm: IRow,
  md: IRow,
  lg: IRow,
  xl: IRow,
  xxl: IRow,
): CSSProperties => {
  const xxlProps = xxl.gap !== undefined ? xxl : props;
  const xlProps = xl.gap !== undefined ? xl : xxlProps;
  const lgProps = lg.gap !== undefined ? lg : xlProps;
  const mdProps = md.gap !== undefined ? md : lgProps;
  const smProps = sm.gap !== undefined ? sm : mdProps;
  const xsProps = xs.gap !== undefined ? xs : mdProps;
  return {
    ...getGapBpStyles(props),
    ...getGapBpStyles(xxlProps, "xxl"),
    ...getGapBpStyles(xlProps, "xl"),
    ...getGapBpStyles(lgProps, "lg"),
    ...getGapBpStyles(mdProps, "md"),
    ...getGapBpStyles(smProps, "sm"),
    ...getGapBpStyles(xsProps, "xs"),
  };
};

export const getBpGridProperties = (
  wrap?: boolean,
  rowGap?: number,
  columnGap?: number,
  direction?: DirectionType,
  align?: AlignsType,
  justify?: JustifyType,
): IRow | undefined => {
  const gap = rowGap !== undefined && columnGap !== undefined ? ([rowGap, columnGap] as [number, number]) : undefined;
  if (
    wrap === undefined &&
    gap === undefined &&
    direction === undefined &&
    align === undefined &&
    justify === undefined
  )
    return undefined;
  return {
    wrap,
    gap,
    direction,
    align,
    justify,
  };
};

export const getBpColProperties = (
  span?: SpanType,
  offset?: OffsetType,
  order?: OrderType,
  align?: TextAlignType,
  valign?: AlignsType,
): ICol | undefined => {
  if (span === undefined && offset === undefined && order === undefined && align === undefined && valign === undefined)
    return undefined;
  return {
    span,
    offset,
    order,
    align,
    valign,
  };
};
