import uniqid from "uniqid";

import { IDelivery, IDeliveryRequestParams, IAddressSuggestionsParams } from "../model";

import { request, setupData } from "shared/api";

export const DeliveryApi = () => {
  const dataDeliveryTypesConfig = setupData(uniqid("delivery-types"), "promotion.get-delivery-types");

  const getDeliveryTypes = async () => {
    return await request([{ ...dataDeliveryTypesConfig }]);
  };

  const dataDeliveryListConfig = setupData(uniqid("delivery-list"), "promotion.get-deliveries");

  const getDeliveryList = async () => {
    return await request([{ ...dataDeliveryListConfig }]);
  };

  const dataDeliveryConfig = setupData(uniqid("delivery"), "promotion.get-delivery");

  const getDelivery = async (params: IDeliveryRequestParams) => {
    return await request([{ ...dataDeliveryConfig, params }]);
  };

  const dataCreateDeliveryConfig = setupData(uniqid("delivery-create"), "promotion.create-delivery");

  const createDelivery = async (params: Omit<IDelivery, "id">) => {
    return await request([{ ...dataCreateDeliveryConfig, params }]);
  };

  const dataEditDeliveryConfig = setupData(uniqid("delivery-edit"), "promotion.update-delivery");

  const editDelivery = async (params: IDelivery) => {
    return await request([{ ...dataEditDeliveryConfig, params }]);
  };

  const dataDeleteDeliveryConfig = setupData(uniqid("delivery-delete"), "promotion.remove-delivery");

  const deleteDelivery = async (params: IDeliveryRequestParams) => {
    return await request([{ ...dataDeleteDeliveryConfig, params }]);
  };

  const dataAddressSuggestionsConfig = setupData(
    uniqid("address-suggestions"),
    "promotion.get-delivery-address-suggestions",
  );

  const getAddressSuggestions = async (params: IAddressSuggestionsParams) => {
    return await request([{ ...dataAddressSuggestionsConfig, params }]);
  };

  return {
    dataDeliveryTypesConfig,
    dataDeliveryListConfig,
    dataDeliveryConfig,
    dataCreateDeliveryConfig,
    dataEditDeliveryConfig,
    dataDeleteDeliveryConfig,
    dataAddressSuggestionsConfig,
    getDeliveryTypes,
    getDeliveryList,
    getDelivery,
    createDelivery,
    editDelivery,
    deleteDelivery,
    getAddressSuggestions,
  };
};
