import { makeAutoObservable, runInAction } from "mobx";
import { formatPhoneNumber, isValidPhoneNumber } from "react-phone-number-input";

import { UserApi, editUserBatch } from "./api";
import { UserProps, SystemType, ContactsProps, BalanceProps, NewPassword } from "./model";

import { NotificationType } from "shared/ui/Notification";
import { getCurrencySymbol } from "shared/utils";

const {
  getUser,
  updateUser,
  getUserContacts,
  updateUserPassword,
  logout,
  getApiToken,
  createApiToken,
  deleteApiToken,
} = UserApi();

export class UserStore {
  user: UserProps | undefined = undefined;
  contacts: ContactsProps | undefined = undefined;
  balance: BalanceProps | undefined = undefined;
  activeTickets: number | undefined = undefined;
  paymentsCount: number | undefined = undefined;
  apiToken: string | undefined = undefined;

  loading = true;
  apiLoading = true;

  constructor() {
    makeAutoObservable(this);
  }

  load = async () => {
    const result = await getUser();
    runInAction(() => {
      if (result) {
        this.user = { ...result.result, system: 1 };
      }
      this.loading = false;
    });
  };

  loadMock = (val: UserProps) => {
    this.user = val;
  };

  loadUserContacts = async () => {
    const result = await getUserContacts();
    runInAction(() => {
      if (result) {
        this.contacts = { ...result.result };
      }
    });
  };

  loadApiToken = async () => {
    this.apiLoading = true;
    const result = await getApiToken();
    runInAction(() => {
      if (result.result) {
        this.apiToken = result.result;
      } else {
        this.apiToken = "";
      }
      this.apiLoading = false;
    });
  };

  get userId(): number | null {
    return this.user?.id || null;
  }

  get userLogin(): string {
    return this.user?.login || "-";
  }

  get userFirstName(): string {
    return this.user?.firstName || "-";
  }

  get userLastName(): string {
    return this.user?.lastName || "-";
  }

  get userSystem(): SystemType | undefined {
    return this.user?.system || undefined;
  }

  get userFullName(): string {
    if (this.user) {
      const name =
        this.user.firstName || this.user.lastName ? this.user.firstName + " " + this.user.lastName : this.user.login;
      return `(${this.userId}) ${name.trim()}`;
    }
    return "";
  }

  get userCurrencySymbol(): string {
    return getCurrencySymbol(this.balance?.currencyCode || "RUB");
  }

  get userCurrencyCode(): string {
    return this.balance?.currencyCode ?? "RUB";
  }

  get userTotalBalance(): string {
    return `${this.balance?.totalBalance} ${this.userCurrencySymbol}`;
  }

  get userAvailableBalance(): string {
    return `${this.balance?.availableBalance} ${this.userCurrencySymbol}`;
  }

  get userBlockedBalance(): string {
    return `${this.balance?.blockedBalance} ${this.userCurrencySymbol}`;
  }

  get userHold(): string {
    return `${this.balance?.hold} ${this.userCurrencySymbol}`;
  }

  get userPhone(): string {
    if (this.contacts && this.contacts.phone) {
      return isValidPhoneNumber(this.contacts.phone) ? formatPhoneNumber(this.contacts.phone) : this.contacts.phone;
    }
    return "-";
  }

  get userSkype(): string {
    return this.contacts?.skype ?? "-";
  }

  get userTelegram(): string {
    return this.contacts?.telegram ?? "-";
  }

  get hasUserPhone(): boolean {
    return Boolean(this.contacts?.phone);
  }

  editUser = async (val: UserProps) => {
    const result = await updateUser(val);
    if (result && result.result) {
      const profileData = await editUserBatch();
      if (profileData.user && profileData.user.result) {
        this.updateUser({ ...profileData.user.result, system: 1 });
      }
      if (profileData.balance && profileData.balance.result) {
        this.updateBalance({ ...profileData.balance.result });
      }
      return { status: "success" as NotificationType };
    }
    if (result && result.error) {
      if (typeof result.error.data === "object") {
        return { status: "error" as NotificationType, fields: { ...result.error.data } };
      }
    }
    return { status: "error" as NotificationType };
  };

  editUserPassword = async (val: NewPassword) => {
    const result = await updateUserPassword(val);

    if (result && result.result) {
      return { status: "success" as NotificationType };
    }
    if (result && result.error) {
      if (typeof result.error.data === "object") {
        return { status: "error" as NotificationType, fields: { ...result.error.data } };
      }
    }
    return { status: "error" as NotificationType };
  };

  updateContacts = (val: ContactsProps) => {
    this.contacts = val;
  };

  updateBalance = (val: BalanceProps) => {
    this.balance = val;
  };

  updateUser = (user: UserProps) => {
    this.user = user;
    this.loading = false;
  };

  updateActiveTickets = (value: number) => {
    this.activeTickets = value;
  };

  updatePaymentsCount = (value: number) => {
    this.paymentsCount = value;
  };

  generateApiToken = async () => {
    const result = await createApiToken();
    if (result.result) {
      this.loadApiToken();
    }
    return result;
  };

  deleteApiToken = async () => {
    const result = await deleteApiToken();
    runInAction(() => {
      if (result.result) {
        this.apiToken = "";
      }
    });
    return result;
  };

  logout = async () => {
    await logout();
    document.location.assign(process.env.REACT_APP_PUBLIC_URL as string);
  };
}
