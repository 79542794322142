import { forwardRef, ReactNode, createElement } from "react";

import cn from "classnames";

import classes from "./Text.module.scss";

type BoldType = "extra" | "semi" | "regular";
interface Props {
  /** Класс для кастомизации */
  className?: string;
  /** Текст */
  children: ReactNode;
  /** Определяет тип тега (строчный или блочный) */
  inline?: boolean;
  /**
   * Размер текста (px)
   * - `xl` -- **16px**
   * - `lg` -- **14px**
   * - `md` -- **12px**
   * - `sm` -- **10px**
   */
  size?: "xl" | "lg" | "md" | "sm";
  /**
   * Жирность заголовока
   * - `regular` -- `regular` или **400**
   * - `semi` -- `semi-bold` или **700**
   * - `extra` -- `extra-bold` или **900**
   */
  bold?: BoldType;
  /**
   * Высота строки
   * - `small` -- **100%**
   * - `normal` -- **120%**
   * - `big` -- **150%**
   */
  height?: "normal" | "big" | "small";
  /** Горизонтальное выравнивание текста в строке. Работает только для блочных элементов (`inline === true`) */
  align?: "left" | "center" | "right" | "justify";
  /** ID элемента */
  id?: string;
}

const getTag = (inline: boolean, bold?: BoldType): string => {
  if (!inline) return "p";
  if (!bold) return "span";
  return "strong";
};

export const Text = forwardRef<HTMLParagraphElement | HTMLSpanElement, Props>(
  ({ id, className, children, bold, inline = false, size = "md", height = "normal", align = "left" }, ref) => {
    const tag = getTag(inline, bold);
    return createElement(
      tag,
      {
        className: cn(
          classes[`size-${size}`],
          bold && classes[`weight-${bold}`],
          classes[`line-height-${height}`],
          !inline && classes[`align-${align}`],
          className,
        ),
        id,
        ref,
      },
      children,
    );
  },
);
