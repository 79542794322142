import { makeAutoObservable, runInAction } from "mobx";

import { NotificationsSettingsApi, notificationsSettingsBatch } from "./api";
import { INotificationSetting, TypeNotificationsSettingsByCategory, TypeChannelStatus, TypeDictionary } from "./model";

import { TogglerStore } from "shared/store/Toggler";
import { NotificationType } from "shared/ui/Notification";

const { getNotificationsSettings, updateNotificationsSetting } = NotificationsSettingsApi();

export class NotificationsSettingsStore {
  settings: INotificationSetting[] | null = null;
  channels: TypeDictionary = null;
  categories: TypeDictionary = null;
  loading = new TogglerStore(true);
  updating = new TogglerStore();
  saving = new TogglerStore();

  constructor() {
    makeAutoObservable(this);
    this.load();
  }

  get isLoading(): boolean {
    return this.loading.value;
  }

  get isUpdating(): boolean {
    return this.updating.value;
  }

  get isSaving(): boolean {
    return this.saving.value;
  }

  get settingsByCategoryId(): TypeNotificationsSettingsByCategory {
    if (this.settings && this.categories) {
      return this.categories.reduce((result, cat) => {
        return {
          ...result,
          [cat.id]: this.settings?.filter((set) => set.categoryId === cat.id) || [],
        };
      }, {});
    }
    return {};
  }

  load = async () => {
    this.loading.update(true);
    const response = await notificationsSettingsBatch();
    runInAction(() => {
      if (response.settings && response.settings.result) {
        this.settings = [...response.settings.result];
      } else {
        this.settings = [];
      }
      if (response.channels && response.channels.result) {
        this.channels = [...response.channels.result];
      } else {
        this.channels = [];
      }
      if (response.categories && response.categories.result) {
        this.categories = [...response.categories.result];
      } else {
        this.categories = [];
      }
      this.loading.update(false);
    });
  };

  loadSettings = async () => {
    this.updating.update(true);
    const response = await getNotificationsSettings();
    runInAction(() => {
      if (response.result) {
        this.settings = [...response.result];
      }
      this.updating.update(false);
    });
  };

  updateSetting = async (triggerId: number, channelId: number, status: TypeChannelStatus) => {
    this.saving.update(true);
    const response = await updateNotificationsSetting({ triggerId, channelId, status });
    return runInAction(() => {
      this.saving.update(false);
      if (response.result) {
        this.loadSettings();
        return { status: "success" as NotificationType };
      }
      if (response.error && typeof response.error.data === "object") {
        return { status: "error" as NotificationType, fields: { ...response.error.data } };
      }
      return { status: "error" as NotificationType };
    });
  };
}
