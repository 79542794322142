import { FC, useRef } from "react";

import cn from "classnames";
import { CSSTransition } from "react-transition-group";

import classes from "./Loader.module.scss";

interface Props {
  className?: string;
  /** Размер иконки лоадера */
  size?: "small" | "normal" | "large";
  /** Цвет */
  color?: "gray" | "yellow";
  /** Включает иконку */
  icon?: boolean;
  /** Включает текст */
  text?: boolean;
  /** Включает абсолютное позиционирование лоадера (по умолчанию `relative`) */
  absolute?: boolean;
  /** Состояние загрузки, скрывает/показывает лоадер */
  loading?: boolean;
}

export const Loader: FC<Props> = ({
  className,
  color = "gray",
  size = "normal",
  absolute = false,
  loading = false,
  icon = true,
  text = true,
}) => {
  const ref = useRef(null);
  return (
    <CSSTransition
      in={loading}
      timeout={400}
      nodeRef={ref}
      classNames={{
        enter: classes["fade-enter"],
        enterActive: classes["fade-enter-active"],
        exit: classes["fade-exit"],
        exitActive: classes["fade-exit-active"],
      }}
      unmountOnExit
    >
      <div
        ref={ref}
        data-testid="loader-container"
        className={cn(className, classes.container, classes[color], absolute && classes["container-absolute"])}
      >
        <div className={classes.loader}>
          {icon && (
            <svg data-testid="loader-icon" viewBox="0 0 42 42" className={cn(classes.icon, classes[`icon-${size}`])}>
              <circle className={classes.dash} cx="21" cy="21" r="19"></circle>
            </svg>
          )}
          {text && (
            <div data-testid="loader-text" className={classes.text}>
              Загрузка
              {!icon && (
                <span data-testid="loader-dots" className={classes.dots}>
                  <span className={classes.dot}>.</span>
                  <span className={classes.dot}>.</span>
                  <span className={classes.dot}>.</span>
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    </CSSTransition>
  );
};
