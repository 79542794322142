import { FC, ReactNode } from "react";

import { RootStoreContext } from "entities/context";
import { RootStore } from "entities/root";

interface Props {
  children?: ReactNode;
}

export const MainProvider: FC<Props> = ({ children }) => {
  return <RootStoreContext.Provider value={new RootStore()}>{children}</RootStoreContext.Provider>;
};
