import { FC } from "react";

import { observer, useLocalObservable } from "mobx-react-lite";
import { Outlet } from "react-router-dom";

import { ReferalStoreContext } from "entities/UserReferal/context";
import { ReferalStore } from "entities/UserReferal/store";
import { TFAStoreContext } from "entities/UserTFA/context";
import { TFAStore } from "entities/UserTFA/store";

export const ProfileProvider: FC = observer(() => {
  const referalStore = useLocalObservable(() => new ReferalStore());
  const tfaStore = useLocalObservable(() => new TFAStore());

  return (
    <ReferalStoreContext.Provider value={referalStore}>
      <TFAStoreContext.Provider value={tfaStore}>
        <Outlet />
      </TFAStoreContext.Provider>
    </ReferalStoreContext.Provider>
  );
});
