import { type JsonrpcResponse } from "shared/api";
import { type CurrencyCodeType } from "shared/utils";

export enum SYSTEMS {
  affiliate = 1,
  broker = 2,
  yoco = 3,
}
export type SystemType = SYSTEMS.affiliate | SYSTEMS.broker | SYSTEMS.yoco;
export interface UserProps {
  id: number;
  login: string;
  password: string;
  firstName?: string;
  lastName?: string;
  system?: SystemType;
}

export interface ContactsProps {
  phone?: string;
  skype?: string;
  telegram?: string;
}

export interface BalanceProps {
  currencyCode?: CurrencyCodeType;
  hold?: number;
  totalBalance?: number;
  availableBalance?: number;
  blockedBalance?: number;
}

export interface NewPassword {
  oldPassword: string;
  newPassword: string;
  newPasswordRepeat: string;
}

export interface IEditUserResponse {
  user: null | JsonrpcResponse;
  balance: null | JsonrpcResponse;
}
