import { createContext, useContext } from "react";

import { GlobalTrafficbackStore, TrafficbackStore, TrafficbacksStore } from "./store";

export const GlobalTrafficbackStoreContext = createContext<GlobalTrafficbackStore | null>(null);

export const useGlobalTrafficbackStore = () => {
  const context = useContext(GlobalTrafficbackStoreContext);
  if (context === null) {
    throw new Error();
  }
  return context;
};

export const TrafficbackStoreContext = createContext<TrafficbackStore | null>(null);

export const useTrafficbackStore = () => {
  const context = useContext(TrafficbackStoreContext);
  if (context === null) {
    throw new Error();
  }
  return context;
};

export const TrafficbacksStoreContext = createContext<TrafficbacksStore | null>(null);

export const useTrafficbacksStore = () => {
  const context = useContext(TrafficbacksStoreContext);
  if (context === null) {
    throw new Error();
  }
  return context;
};
