import { FC } from "react";

import { BaseModal, BaseModalProps } from "shared/ui/BaseModal";
import { BasePortal } from "shared/ui/BasePortal";

const portalNode = document.createElement("div");
portalNode.setAttribute("id", "modal-portal");
portalNode.setAttribute("data-testid", "modal-portal");

/**
 * Для стилизации модального окна, можно использовать пропы кастомных классов:
 * - `dialogClassName`
 * - `bodyClassName`
 * - `headerClassName`
 * - `titleClassName`
 * - `footerClassName`
 * - `cancelBtnClassName`
 * - `targetBtnClassName`
 *
 * По умолчанию ширина модального окна подстраивается под количество контента.
 * Чтобы изменить ширину, нужно воспользоваться пропом `dialogClassName`.
 *
 * Видимость модального окна зависит от занчения пропа `open`.
 *
 * Пропсы функций:
 * - `setOpen` -- функция, отвечающая за открытие/закрытие модалки, изменяент проп `open`.
 * - `targetBtnHandler` -- функция, отрабатывающая при клике на целевую кнопку.
 * - `cancelBtnHandler` -- функция, отрабатывающая при клике на кнопку отмены.
 *
 * Если заголовок задан пустой строкой, а отображения кнопки закрытия отключено - хедер не будет выводиться.
 * Если текст для кнопок отмены и целевого действия задан пустой строкой - футер не будет выводиться.
 */
export const Modal: FC<BaseModalProps> = ({ children, ...props }) => {
  return (
    <BasePortal portalNode={portalNode}>
      <BaseModal {...props}>{children}</BaseModal>
    </BasePortal>
  );
};
